import React from "react"
import PlansPage from "../components/pages/plans"
import SEO from "../components/seo"

const PlanosPage = () => (
  <>
    <SEO title="Planos e Preços" />
    <PlansPage />
  </>
)

export default PlanosPage
