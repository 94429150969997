import React from "react"
import styled from "@emotion/styled"
import { mq } from "../../helpers"
import Text from "../../text"

const Wrapper = styled.div`
  ${mq({
    display: "flex",
    flexDirection: "row",
    fontSize: "15px",
    marginTop: "20px",
    ":first-of-type": {
      marginTop: "26px",
    },
  })}
`

const FeatureImage = styled.div`
  ${({ src }) =>
    mq({
      width: "22px",
      height: "22px",
      maskImage: `url(${src})`,
      backgroundColor: "#4680fe",
      objectFit: "contain",
      marginRight: "20px",
      opacity: 0.6,
    })}
`

const FeatureText = styled(Text)`
  ${mq({
    fontSize: "15px",
    fontWeight: 600,
    color: "#7d7d7d",
  })}
`

export default function FeatureItem({ image, text }) {
  return (
    <Wrapper>
      <FeatureImage src={image} />
      <FeatureText>{text}</FeatureText>
    </Wrapper>
  )
}
